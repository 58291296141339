/*
 * @Description: 路由配置
 * @Author: kong
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: kong
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// const routes =

const router = new Router({
  // base: '/dist',
  mode: 'history',
  routes :[

    {
      path: '/home',
      name: 'Home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('../components/Error.vue')
    },
    {
      path: '/goods',
      name: 'Goods',
      component: () => import('../views/Goods.vue')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue')
    },
    {
      path: '/goods/details',
      name: 'Details',
      component: () => import('../views/Details.vue')
    },
    {
      path: '/shoppingCart',
      name: 'ShoppingCart',
      component: () => import('../views/ShoppingCart.vue'),
      meta: {
        requireAuth: true // 需要验证登录状态
      }
    },
    // {
    //   path: '/collect',
    //   name: 'Collect',
    //   component: () => import('../views/Collect.vue'),
    //   meta: {
    //     requireAuth: true // 需要验证登录状态
    //   }
    // },
    {
      path: '/order',
      name: 'Order',
      component: () => import('../views/Order.vue'),
      meta: {
        requireAuth: true // 需要验证登录状态
      }
    },
    {
      path: '/confirmOrder',
      name: 'ConfirmOrder',
      component: () => import('../views/ConfirmOrder.vue'),
      meta: {
        requireAuth: true // 需要验证登录状态
      }
    },
    {
      path: '/services',
      name: 'Services',
      component: () => import('../views/services/index.vue')
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('../views/faq/index.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/contact/index.vue')
    },
    {
      path: '/',
      name: 'product',
      component: () => import('../views/product/main/index.vue')
    },
    {
      path: '/product-list',
      name: 'product-list',
      meta:{
        isKeepAlive:''
      },
      component: () => import('../views/product/list/index.vue')
    },
    {
      path: '/placard',
      name: 'placard',
      component: () => import('../views/placard/index.vue')
    },
    {
      path: '/product-detail',
      name: 'product-detail',
      component: () => import('../views/product/details/index.vue')
    },
    {
      path: '/footmark',
      name: 'footmark',
      component: () => import('../views/footmark/index.vue')
    },
    {
      path: '/collect',
      name: 'collect',
      component: () => import('../views/collect/index.vue')
    },{
      path: '/catalogue',
      name: 'catalogue',
      component: () => import('../views/catalogue/index.vue')
    },{
      path: '/feedback',
      name: 'feedback',
      component: () => import('../views/feedback/index.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/index.vue')
    }
  ]
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
